import React from "react";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import ColleagueForm from "./ColleagueForm";
import FriendsForm from "./FriendsForm";

const RoommatesForm = ({userData, handleChangeType, roommates, handleRoommatesDetails}) => {

    return (
        <Box>
            <FormControl sx={{width: 200}}>
                <InputLabel id="demo-simple-select-label">+ 1</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={roommates.filter(d => d.id === 'type')[0].value}
                    label={"+ 1"}
                    onChange={handleChangeType}
                    variant={"standard"}
                >
                    <MenuItem value={'colleague'}>Colleague</MenuItem>
                    <MenuItem value={'friends'}>Friends & Family</MenuItem>
                </Select>
            </FormControl>
            {roommates.filter(d => d.id === 'type')[0].value === 'colleague' && <ColleagueForm userData={userData} handleRoommatesDetails={handleRoommatesDetails}/>}
            {roommates.filter(d => d.id === 'type')[0].value === 'friends' && <FriendsForm userData={userData} handleRoommatesDetails={handleRoommatesDetails}/>}
        </Box>
    )
}

export default RoommatesForm
