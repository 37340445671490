import React, {useEffect, useState} from "react";
import {Box, Card, FormControl, TextField, useTheme} from "@mui/material";

const ColleagueForm = ({userData, handleRoommatesDetails}) => {
    const theme = useTheme()
    const [details, setDetails] = useState([
        {id: 'name', title: 'Name', value: '', compiled: false, required: true},
        {id: 'surname', title: 'Surname', value: '', compiled: false, required: true},
    ])

    useEffect(() => {
        compileData().then()
    }, [])

    async function compileData () {
        let dati= details
        dati.map(d => d.value = userData.accommodation.roommates[d.id])
        setDetails([...dati])
    }

    const handleCompileGeneral = (e, id) => {
        let newArr = details.map(obj => {
            if (obj.id === id) {
                return {...obj, value: e.target.value};
            }
            return obj;
        });
        setDetails(newArr)
        handleRoommatesDetails(newArr)
    }

    return(
        <Card component={"form"} sx={{
            '& > :not(style)': {p: 1}, mt:2
        }}>
            {details.map(g =>
                <Box key={g.id} sx={{
                    width: '50%',
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    }, marginTop: '0.3rem'
                }}>
                    <TextField label={g.title} variant={"standard"} disabled={g.compiled} required={g.required} id="name-input" value={g.value}
                               onChange={(e) => handleCompileGeneral(e, g.id)}/>
                </Box>
            )}
        </Card>
    )
}

export default ColleagueForm
