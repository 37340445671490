import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio,
    RadioGroup, Select, Snackbar,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "../services/auth-header";
import {Alert} from "@mui/lab";

const CreateUser = ({open, handleClose}) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [area, setArea] = useState('');
    const [deptCountry, setDeptCountry] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [pagante, setPagante] = useState(0);
    const [sendEmail, setSendEmail] = useState(true);
    const [sending, setSending] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [error, setError] = useState('Email già presente in lista, è stato comunque reinviato l\'invito con le credenziali');

    const handleCloseError = () => setOpenError(false);

    async function sendData() {
        setSending(true);
        const user = {
            email: email,
            name: name,
            surname: surname,
            pagante: pagante,
            area: area,
            dept_country: deptCountry,
            jobTitle: jobTitle,
            sendEmail: sendEmail
        }
        try {
            await axios.post(API_URL + 'user', user, {headers: authHeader()})
            setSending(false);
            handleClose('success');
        } catch (e) {
            setOpenError(true);
            setError('Email già presente in lista');
            setSending(false);
            console.log(e.response.data.error);
            //handleClose(e);
        }
    }

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Aggiungi Utente</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Inserisci tutte le informazioni richieste
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Indirizzo Email"
                type="email"
                fullWidth
                variant="standard"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nome"
                type="text"
                fullWidth
                variant="standard"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <TextField
                autoFocus
                margin="dense"
                id="surname"
                label="Cognome"
                type="text"
                fullWidth
                variant="standard"
                required
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
            />
            <TextField
                autoFocus
                margin="dense"
                id="area"
                label="Cluster"
                type="text"
                fullWidth
                variant="standard"
                value={area}
                onChange={(e) => setArea(e.target.value)}
            />
            <TextField
                autoFocus
                margin="dense"
                id="dept_country"
                label="Department"
                type="text"
                fullWidth
                variant="standard"
                value={deptCountry}
                onChange={(e) => setDeptCountry(e.target.value)}
            />
            <TextField
                autoFocus
                margin="dense"
                id="jobTitle"
                label="Job Title"
                type="text"
                fullWidth
                variant="standard"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
            />
            <FormControl sx={{mt: 2}} fullWidth>
                <FormLabel id="pagante-radio-buttons-group-label">Pagante</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="pagante-radio-buttons-group-label"
                    value={pagante}
                    onChange={(e) => setPagante(parseInt(e.target.value))}
                    name="radio-buttons-group"
                >
                    <FormControlLabel value={1} control={<Radio />} label="Sì" />
                    <FormControlLabel value={0} control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Annulla</Button>
            <Button onClick={sendData} disabled={name.length === 0 || surname.length === 0 || email.length === 0 || sending}>Conferma e Invia Email</Button>
        </DialogActions>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
            <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                {error}
            </Alert>
        </Snackbar>
    </Dialog>;
}

export default CreateUser;
