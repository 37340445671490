import {
    GridCsvExportOptions,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton, GridToolbarQuickFilter,
    useGridApiContext
} from "@mui/x-data-grid";
import {Button} from "@mui/material";
import React from "react";
import {DownloadForOffline, Downloading} from "@mui/icons-material";

export const GridToolbarButton = ({startIcon, label, onClick, disabled}) => {
    return (
        <Button size="small" startIcon={startIcon} onClick={onClick} disabled={disabled}>
            {label}
        </Button>
    )
}

const CustomToolbar = ({buttons = [], exportedFields = null, totalExport = true}) => {
    const apiRef = useGridApiContext();
    const handleExport = (options: GridCsvExportOptions) =>
        apiRef.current.exportDataAsCsv(options);

    return (
        <GridToolbarContainer>
            {<GridToolbarColumnsButton />}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {<GridToolbarExport csvOptions={{utf8WithBom: true}}/> /** pulsante export di default */}
            {/*<GridToolbarButton
                startIcon={<Download/>}
                label={"Esporta colonne visibili"}
                onClick={handleExport}
            />*/}
            {
                exportedFields &&
                    <GridToolbarButton
                        startIcon={<Downloading/>}
                        label={"Colonne custom"}
                        onClick={() => {
                            handleExport({utf8WithBom: true, fields: exportedFields})
                        }}
                    />
            }
            {
                totalExport &&
                    <GridToolbarButton
                        startIcon={<DownloadForOffline/>}
                        label={"Esporta tutte le colonne"}
                        onClick={() => {
                            handleExport({utf8WithBom: true, allColumns: true})
                        }}
                    />
            }
            <GridToolbarQuickFilter sx={{ml: 'auto'}} debounceMs={500} />
            {buttons?.map((button, index) => <div key={index}>{button}</div>) /** eventuali buttons aggiuntivi custom */}
        </GridToolbarContainer>
    );
}

export default CustomToolbar
