import React, {useEffect, useState} from "react";
import {Box, Card, FormControl, Stack, TextField, useTheme} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const FriendsForm = ({userData, handleRoommatesDetails}) => {
    const theme = useTheme()
    const [details, setDetails] = useState([
        {id: 'name', title: 'Name', value: '', compiled: true, required: true},
        {id: 'surname', title: 'Surname', value: '', compiled: true, required: true},
        {id: 'street', title: 'Address', value: '', compiled: true, required: true},
        {id: 'zip_code', title: 'ZIP Code', value: '', compiled: true, required: true},
        {id: 'district', title: 'District', value: '', compiled: true, required: false},
        {id: 'town', title: 'Town', value: '', compiled: true, required: true},
        {id: 'country', title: 'Country', value: '', compiled: true, required: true},
        {id: 'place_of_birth', title: 'Place of Birth', value: '', compiled: true, required: true},
        {id: 'date_of_birth', type: 'datePicker', title: 'Date of Birth', value: '', compiled: true, required: true},
        {id: 'nationality', title: 'Nationality', value: '', compiled: true, required: true},
        {id: 'doc_type', title: 'Document Type', value: '', compiled: true, required: true},
        {id: 'doc_number', title: 'Document Number', value: '', compiled: true, required: true},
        {id: 'issued_on', type:'datePicker', title: 'Issued On', value:null, compiled: true, required: true},
        {id: 'issued_in', title: 'Issued In', value: '', compiled: true, required: true},
        {id: 'expiry_date', type:'datePicker', title: 'Expiry Date', value: null, compiled: true, required: true},
        {id: 'dietary_requirements', title: 'Dietary Requirements', value: null, compiled: true, required: true},
    ])

    useEffect(() => {
        compileData().then()
    }, [])

    async function compileData () {
        let dati= details
        dati.map(d => d.value = userData.accommodation.roommates[d.id])
        setDetails([...dati])
    }

    const handleCompileGeneral = (e, id) => {
        let newArr = details.map(obj => {
            if (obj.id === id) {
                return {...obj, value: e.target.value};
            }
            return obj;
        });
        setDetails(newArr)
        handleRoommatesDetails(newArr, e.target.value, id)
    }

    const handleCompileType = (value, id) => {
        let newArr = details.map(obj => {
            if (obj.id === id) {
                return {...obj, value: value};
            }
            return obj;
        });
        setDetails(newArr)
        handleRoommatesDetails(newArr, value, id)
    }

    return(
        <Card style={{width:'100%'}} component={"form"} sx={{
            '& > :not(style)': {p: 1}, mt:2, pb:2
        }}>
            {details.map(g =>
                <FormControl key={g.id} sx={{
                    width: '50%',
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    }, marginTop: '0.3rem'
                }}>
                    {g.type==='datePicker' ?
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label={g.title}
                                inputFormat="dd/MM/yyyy"
                                value={g.value}
                                renderInput={(params) =>
                                    <TextField {...params} required id="name-input" variant={'standard'}/>}
                                onChange={(e) => handleCompileType(e, g.id)}/>
                        </LocalizationProvider>
                        :
                        <Box width={'100%'}>
                            <TextField variant={"standard"} label={g.title} fullWidth required={g.required} id="name-input" value={g.value}
                                       onChange={(e) => handleCompileGeneral(e, g.id)}/>
                        </Box>
                    }
                </FormControl>
            )}
        </Card>
    )
}

export default FriendsForm
