import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {
    Box,
    Button,
    Container,
    Grid,
    Snackbar,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import logo from '../assets/images/logo.png'
import {DataGrid, GridToolbar, itIT} from '@mui/x-data-grid'
import UserService from "../services/user.service";
import {Add, Check, Close} from "@mui/icons-material";
import CreateUser from "../components/CreateUser";
import {Alert} from "@mui/lab";
import CustomGridToolbar from "../components/CustomGridToolbar";
import {GridRowsProp} from "@mui/x-data-grid";

const Home = () => {
    let navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [allUsers, setAllUsers] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [filter, setFilter] = useState('complete')

    const exportedCustomFields = ['name', 'surname', 'i_am', 'country', 'email','accommodation_bed','in_date','out_date','guest_type','guest_name','guest_surname','pagante']

    const [openSuccess, setOpenSuccess] = React.useState(false);

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        street: false,
        town: false,
        zip_code: false,
        place_of_birth: false,
        date_of_birth: false,
        district: false,
        nationality: false,
        country: false,
        dietary_requirements: false,
        dietary_specific: false,
        in_date: false,
        out_date: false,
        doc_number: false,
        doc_type: false,
        expiry_date: false,
        issued_in: false,
        issued_on: false,
        accommodation_bed: false,
        secondary_email: false,
        compensation_from: false,
        compensation_transport: false,
        logistic_arrival_airline: false,
        logistic_arrival_flight_number: false,
        logistic_arrival_airport: false,
        logistic_arrival_arrive_date: false,
        logistic_arrival_arrive_time: false,
        logistic_arrival_departure: false,
        logistic_arrival_transport: false,
        logistic_departure_airline: false,
        logistic_departure_flight_number: false,
        logistic_departure_airport: false,
        logistic_departure_arrive_date: false,
        logistic_departure_arrive_time: false,
        logistic_departure_departure: false,
        logistic_departure_transport: false,
        logistic_from_city: false,
        logistic_from_country: false,
        logistic_transport_first_step: false,
        guest_type: false,
        guest_name: false,
        guest_surname: false,
        guest_country: false,
        guest_date_of_birth: false,
        guest_district: false,
        guest_doc_number: false,
        guest_doc_type: false,
        guest_expiry_date: false,
        guest_issued_in: false,
        guest_issued_on: false,
        guest_nationality: false,
        guest_place_of_birth: false,
        guest_town: false,
        guest_zip_code: false,
        guest_dietary_requirements: false,
        guest_colleague_name: false,
        guest_colleague_surname: false,
        privacy1: false,
        privacy2: false,
        privacy3: false,
        deptCountry: true,
        area: true,
        jobTitle: true,
    });

    const columns = [
        {field: 'name', headerName: 'First name', flex: 1},
        {field: 'surname', headerName: 'Last name', flex: 1},
        {
            field: 'complete', headerName: 'Iscritto', flex: 1,
            valueFormatter: (params) => {
                if (params.value) {
                    return 'Sì';
                } else return 'No';
            },
            renderCell: (params) => params.value === true
                ? <Check sx={{color: 'green'}}/>
                : params.value === false
                    ? <Close color={'disabled'}/>
                    : '-'
        },
        {
            field: 'participatePerson', headerName: 'In Presenza', flex: 1,
            valueFormatter: (params) => {
                if (params.value === "yes") {
                    return 'Sì';
                } else return 'No';
            },
            renderCell: (params) => params.value === "yes"
                ? <Check sx={{color: 'green'}}/>
                : params.value === "no"
                    ? <Close color={'disabled'}/>
                    : '-'
        },
        {field: 'deptCountry', headerName: 'deptCountry', flex: 1},
        {field: 'area', headerName: 'area', flex: 1},
        {field: 'jobTitle', headerName: 'jobTitle', flex: 1},
        {field: 'i_am', headerName: 'Cluster', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'country', headerName: 'Country', flex: 1},
        {field: 'date_of_birth', headerName: 'Date of Birth', flex: 1,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'district', headerName: 'District', flex: 1},
        {field: 'mobile', headerName: 'Mobile', flex: 1},
        {field: 'nationality', headerName: 'Nationality', flex: 1},
        {field: 'place_of_birth', headerName: 'Place of Birth', flex: 1},
        {field: 'secondary_email', headerName: 'Secondary Email', flex: 1},
        {field: 'street', headerName: 'Street', flex: 1},
        {field: 'town', headerName: 'Town', flex: 1},
        {field: 'zip_code', headerName: 'Zip Code', flex: 1},
        {field: 'doc_number', headerName: 'Doc Number', flex: 1, valueGetter: (params) => params.row.document?.doc_number},
        {field: 'doc_type', headerName: 'Doc Type', flex: 1, valueGetter: (params) => params.row.document?.doc_type},
        {field: 'expiry_date', headerName: 'Expiry Date', flex: 1, valueGetter: (params) => params.row.document?.expiry_date,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'issued_in', headerName: 'Issued In', flex: 1, valueGetter: (params) => params.row.document?.issued_in},
        {field: 'issued_on', headerName: 'Issued On', flex: 1, valueGetter: (params) => params.row.document?.issued_on},
        {field: 'accommodation_bed', headerName: 'Accommodation', flex: 1, valueGetter: (params) => params.row.accommodation?.bed},
        {field: 'dietary_requirements', headerName: 'Dietary Requirements', flex: 1, valueGetter: (params) => params.row.accommodation?.dietary_requirements},
        {field: 'dietary_specific', headerName: 'Dietary Specifc Requirements', flex: 1, valueGetter: (params) => params.row.accommodation?.dietary_specific},
        {field: 'in_date', headerName: 'Accommodation In Date', flex: 1, valueGetter: (params) => params.row.accommodation?.in_date,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'out_date', headerName: 'Accommodation Out Date', flex: 1, valueGetter: (params) => params.row.accommodation?.out_date,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'guest_type', headerName: 'Accommodation Guest Type', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.type},
        {field: 'guest_name', headerName: 'Accommodation Guest Name', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.name},
        {field: 'guest_surname', headerName: 'Accommodation Guest Surname', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.surname},
        {field: 'guest_country', headerName: 'Accommodation Guest Country', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.country},
        {field: 'guest_date_of_birth', headerName: 'Accommodation Guest Date of Birth', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.date_of_birth,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'guest_district', headerName: 'Accommodation Guest District', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.district},
        {field: 'guest_doc_number', headerName: 'Accommodation Guest Doc Number', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.doc_number},
        {field: 'guest_doc_type', headerName: 'Accommodation Guest Doc Type', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.doc_type},
        {field: 'guest_expiry_date', headerName: 'Accommodation Guest Doc Expiry Date', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.expiry_date,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'guest_issued_in', headerName: 'Accommodation Guest Doc Issued In', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.issued_in},
        {field: 'guest_issued_on', headerName: 'Accommodation Guest Doc Issued On', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.issued_on,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'guest_nationality', headerName: 'Accommodation Guest Nationality', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.nationality},
        {field: 'guest_place_of_birth', headerName: 'Accommodation Guest Place of Birth', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.place_of_birth},
        {field: 'guest_town', headerName: 'Accommodation Guest Town', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.town},
        {field: 'guest_zip_code', headerName: 'Accommodation Guest ZIP Code', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.zip_code},
        {field: 'guest_dietary_requirements', headerName: 'Accommodation Guest Dietary Requirements', flex: 1, valueGetter: (params) => params.row.accommodation?.roommates?.dietary_requirements},
        {field: 'logistic_arrival_departure', headerName: 'Logistic - Arrival From Airport', flex: 1, valueGetter: (params) => params.row.logistic?.arrivalFromAirport},
        {field: 'logistic_arrival_airport', headerName: 'Logistic - Arrival Airport', flex: 1, valueGetter: (params) => params.row.logistic?.arrivalToAirport},
        {field: 'logistic_arrival_airline', headerName: 'Logistic - Arrival Airline', flex: 1, valueGetter: (params) => params.row.logistic?.arrivalAirline},
        {field: 'logistic_arrival_flight_number', headerName: 'Logistic - Arrival Flight Nr.', flex: 1, valueGetter: (params) => params.row.logistic?.arrivalFlightNumber},
        {field: 'logistic_arrival_arrive_date', headerName: 'Logistic - Arrival Date', flex: 1,
            valueGetter: (params) => params.row.logistic?.arrivalDateTime,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'logistic_arrival_arrive_time', headerName: 'Logistic - Arrival Time', flex: 1,
            valueGetter: (params) => params.row.logistic?.arrivalDateTime,
            valueFormatter: (params) => {
                const options = { hour: 'numeric', minute: 'numeric' };
                return params.value ? new Date(params.value).toLocaleTimeString('it-IT', options) : '';
            },
        },
        {field: 'logistic_arrival_transport', headerName: 'Logistic - Arrival Transport', flex: 1, valueGetter: (params) => params.row.logistic?.arrivalTransport},
        {field: 'logistic_departure_airport', headerName: 'Logistic - Departure Airport', flex: 1, valueGetter: (params) => params.row.logistic?.departureToAirport},
        {field: 'logistic_departure_departure', headerName: 'Logistic - Departure From Airport', flex: 1, valueGetter: (params) => params.row.logistic?.departureFromAirport},
        {field: 'logistic_departure_airline', headerName: 'Logistic - Departure Airline', flex: 1, valueGetter: (params) => params.row.logistic?.departureAirline},
        {field: 'logistic_departure_flight_number', headerName: 'Logistic - Departure Flight Nr.', flex: 1, valueGetter: (params) => params.row.logistic?.departureFlightNumber},
        {field: 'logistic_departure_arrive_date', headerName: 'Logistic - Departure Date', flex: 1,
            valueGetter: (params) => params.row.logistic?.departureDateTime,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleDateString('it-IT') : '';
            },
        },
        {field: 'logistic_departure_arrive_time', headerName: 'Logistic - Departure Time', flex: 1,
            valueGetter: (params) => params.row.logistic?.departureDateTime,
            valueFormatter: (params) => {
                const options = { hour: 'numeric', minute: 'numeric' };
                return params.value ? new Date(params.value).toLocaleTimeString('it-IT', options) : '';
            },
        },
        {field: 'logistic_departure_transport', headerName: 'Logistic - Departure Transport', flex: 1, valueGetter: (params) => params.row.logistic?.departureTransport},
        {field: 'logistic_from_city', headerName: 'Logistic - 1st Step From City', flex: 1, valueGetter: (params) => params.row.logistic?.fromCity},
        {field: 'logistic_from_country', headerName: 'Logistic - 1st Step From Country', flex: 1, valueGetter: (params) => params.row.logistic?.fromCountry},
        {field: 'logistic_transport_first_step', headerName: 'Logistic - 1st Step Transport', flex: 1, valueGetter: (params) => params.row.logistic?.transportFirstStep},
        {
            field: 'privacy1', headerName: 'Privacy Foto e Video', flex: 1,
            valueGetter: (params) => params.row.privacy?.privacy_1,
            valueFormatter: (params) => {
                if (params.value === "yes") {
                    return 'Sì';
                } else return 'No';
            },
            renderCell: (params) => params.value === "yes"
                ? <Check sx={{color: 'green'}}/>
                : params.value === false
                    ? <Close color={'disabled'}/>
                    : '-'
        },
        {
            field: 'privacy2', headerName: 'Privacy Iscrizione', flex: 1,
            valueGetter: (params) => params.row.privacy?.privacy_2,
            valueFormatter: (params) => {
                if (params.value === "yes") {
                    return 'Sì';
                } else return 'No';
            },
            renderCell: (params) => params.value === "yes"
                ? <Check sx={{color: 'green'}}/>
                : params.value === false
                    ? <Close color={'disabled'}/>
                    : '-'
        },
        {
            field: 'privacy3', headerName: 'Privacy Eventi Futuri', flex: 1,
            valueGetter: (params) => params.row.privacy?.privacy_3,
            valueFormatter: (params) => {
                if (params.value === "yes") {
                    return 'Sì';
                } else return 'No';
            },
            renderCell: (params) => params.value === "yes"
                ? <Check sx={{color: 'green'}}/>
                : params.value === "no"
                    ? <Close color={'disabled'}/>
                    : '-'
        },
        {field: 'completedAt', headerName: 'Registered At', flex: 1,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value?._seconds * 1000).toLocaleString('it-IT') : '';
            },
        },
        {
            field: 'pagante', headerName: 'Pagante', flex: 1,
            valueFormatter: (params) => {
                if (params.value) {
                    return 'Sì';
                } else return 'No';
            },
            renderCell: (params) => params.value === true
                ? <Check sx={{color: 'green'}}/>
                : params.value === false
                    ? <Close color={'disabled'}/>
                    : '-'
        },
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function handleChangeFilter(event) {
        setFilter(event.target.value);
    }

    useEffect(() => {
        fetchData().then()
    }, [])

    async function fetchData() {
        const response = await UserService.getUsers()
        setAllUsers(response.data)
        setLoading(false)
    }

    const users: GridRowsProp = useMemo(() => {
        if(!allUsers)
            return []
        else return allUsers.filter(function (o) {
            return !o.roles && !o.test
        })
    }, [allUsers])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleCloseSuccess = () => setOpenSuccess(false);
    function handleClose(res) {
        console.log(res);
        setOpen(false);
        if (res === 'success') {
            setOpenSuccess(true);
        }
    }

    return (
        <main>
            <Box style={{background:'#0d99d8'}}>
                <Container maxWidth={false}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{width:'100%', maxWidth:'180px'}}>
                            <img src={logo} width={'100%'} style={{paddingRight:'5rem', margin:'1rem 0'}}/>
                        </div>
                    </div>
                </Container>
            </Box>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}} maxWidth={false}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                    <Typography variant={"h4"} sx={{textAlign: 'left'}}>Elenco Registrati</Typography>
                    {<Button variant="contained" endIcon={<Add />} onClick={handleOpen}>
                        Aggiungi Utente
                    </Button>}
                </Box>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography sx={{mr: 2}}>
                        Filtra:
                    </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        value={filter}
                        exclusive
                        onChange={handleChangeFilter}
                        aria-label="Filtro"
                        sx={{mr: 5}}
                    >
                        <ToggleButton value="all">Tutti</ToggleButton>
                        <ToggleButton value="complete">Iscritti</ToggleButton>
                    </ToggleButtonGroup>
                    <Grid container>
                        <Grid item xs={3}>
                            <Stack direction={"column"} alignItems={"start"} sx={{textAlign: "left"}}>
                                Utenti invitati<br/>totali
                                <Typography variant={"h4"}>
                                    {users.length}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <Stack direction={"column"} alignItems={"start"} sx={{textAlign: "left"}}>
                                Utenti iscritti<br/>(in presenza e online)
                                <Typography variant={"h4"}>
                                    {users.filter((u) => u.complete).length}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <Stack direction={"column"} alignItems={"start"} sx={{textAlign: "left"}}>
                                Utenti iscritti<br/>(in presenza)
                                <Typography variant={"h4"}>
                                    {users.filter((u) => u.complete && u.participatePerson == "yes").length}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>

                <div style={{display: 'flex', height: 670, marginTop: '1rem'}}>
                    <div style={{flexGrow: 1}}>
                        <DataGrid
                            localeText={{
                                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                                toolbarExport: "Esporta colonne visibili"
                            }}
                            loading={loading}
                            columnVisibilityModel={columnVisibilityModel}
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'surname', sort: 'asc'}],
                                },
                            }}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            disableSelectionOnClick
                            onRowClick={(params, event, details) => {
                                navigate("/user/" + params.row.id);
                                // TODO: Show row details
                            }}
                            components={{
                                Toolbar: () => {
                                    return <CustomGridToolbar
                                        exportedFields={exportedCustomFields}
                                    />
                                } }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            columns={columns}
                            rows={(filter === 'complete' ? users.filter((u) => u.complete) : users)||[]}
                        />
                    </div>
                </div>
            </Container>
            <CreateUser open={open} handleClose={handleClose} />
            <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                    Aggiornamento effettuato con successo
                </Alert>
            </Snackbar>
        </main>
    )
}

export default Home
