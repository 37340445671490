import React, {useState, Suspense, useEffect} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Snackbar,
    Stack, TextareaAutosize,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import logo from '../assets/images/logo.png'
import {DataGrid, GridToolbar, itIT} from '@mui/x-data-grid';
import UserService from "../services/user.service";
import {GridCsvExportOptions} from '@mui/x-data-grid';
import {ArrowBack, Check, Close, Save} from "@mui/icons-material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider, MobileDateTimePicker} from '@mui/x-date-pickers'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import {Alert} from "@mui/lab";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import RoommatesForm from "../components/RoommatesForm";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "../services/auth-header";

const User = () => {
    let navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [changes, setChanges] = useState({});
    const [open, setOpen] = React.useState(false);
    const [presenza, setPresenza] = useState(null);
    const [oldPresenza, setOldPresenza] = useState(null);

    const {id} = useParams()
    const theme = useTheme()

    const [general, setGeneral] = useState([
        {id: 'name', title: 'Name', value: '', compiled: false, required: true},
        {id: 'surname', title: 'Surname', value: '', compiled: false, required: true},
        {id: 'street', title: 'Address', value: '', compiled: false, required: true},
        {id: 'zip_code', title: 'ZIP Code', value: '', compiled: false, required: true},
        {id: 'district', title: 'District', value: '', compiled: false, required: false},
        {id: 'town', title: 'Town', value: '', compiled: false, required: true},
        {id: 'country', title: 'Country', value: '', compiled: false, required: true},
        {id: 'place_of_birth', title: 'Place of Birth', value: '', compiled: false, required: true},
        {id: 'date_of_birth', title: 'Date of Birth', type: 'datePicker', value: new Date(user?.date_of_birth), compiled: false, required: true},
        {id: 'nationality', title: 'Nationality', value: '', compiled: false, required: true},
    ])

    const [document, setDocument] = useState([
        {id: 'doc_type', title: 'Document Type', value: '', compiled: true, required: true},
        {id: 'doc_number', title: 'Document Number', value: '', compiled: false, required: true},
        {id: 'issued_on', type: 'datePicker', title: 'Issued On', value: null, compiled: false, required: true},
        {id: 'issued_in', title: 'Issued In', value: '', compiled: false, required: true},
        {id: 'expiry_date', type: 'datePicker', title: 'Expiry Date', value: null, compiled: false, required: true},
    ])

    const [contact, setContact] = useState([
        {id: 'email', title: 'Email', value: '', compiled: true, required: true},
        {id: 'secondary_email', title: 'Secondary Email', value: '', compiled: false, required: false},
        {id: 'mobile', title: 'Mobile', value: '', compiled: false, required: true},
    ])

    const [accommodation, setAccommodation] = useState([
        {
            id: 'in_date',
            title: 'In Date',
            value: new Date(user?.accommodation?.in_date),
            compiled: false,
            required: true,
            type: 'datepicker'
        },
        {
            id: 'out_date',
            title: 'Out Date',
            value: new Date(user?.accommodation?.out_date),
            compiled: false,
            required: true,
            type: 'datepicker'
        },
        {
            id: 'dietary_requirements',
            title: 'Dietary Requirements',
            value: '',
            compiled: false,
            required: false,
            type: 'radiogroup',
            fields: [{value: "yes", label: 'Yes'},{value: "no", label: 'No'}]
        },
        {id: 'dietary_specific', title: 'Dietary Specific', compiled: false, required: true, type: 'textbox', value: ''},
        {
            id: 'bed',
            fields: [
                {value: "alone", label: 'Alone'},
                {value: "king", label: 'Sharing a King Bed'},
                {value: "twin", label: 'Sharing a Twin Bed'}
            ], value: '', compiled: false, required: true, type: 'radiogroup'
        },
    ])

    const [roommates, setRoommates] = useState([
        {id: 'type', title: 'Type', value: '', compiled: true, required: true},
        {id: 'name', title: 'Name', value: '', compiled: false, required: true},
        {id: 'surname', title: 'Surname', value: '', compiled: false, required: true},
        {id: 'street', title: 'Address', value: '', compiled: false, required: true},
        {id: 'zip_code', title: 'ZIP Code', value: '', compiled: false, required: true},
        {id: 'district', title: 'District', value: '', compiled: false, required: false},
        {id: 'town', title: 'Town', value: '', compiled: false, required: true},
        {id: 'country', title: 'Country', value: '', compiled: false, required: true},
        {id: 'place_of_birth', title: 'Place of Birth', value: '', compiled: false, required: true},
        {id: 'date_of_birth', title: 'Date of Birth', value: '', compiled: false, required: true, type: 'datepicker'},
        {id: 'nationality', title: 'Nationality', value: '', compiled: false, required: true},
        {id: 'doc_type', title: 'Doc Type', value: '', compiled: false, required: true},
        {id: 'doc_number', title: 'Doc Number', value: '', compiled: false, required: true},
        {id: 'issued_on', title: 'Doc Issued On', value: null, compiled: false, required: true, type: 'datepicker'},
        {id: 'issued_in', title: 'Doc Issued In', value: '', compiled: false, required: true},
        {
            id: 'expiry_date',
            title: 'step2.expiryDate',
            value: null,
            compiled: false,
            required: true,
            type: 'datepicker'
        },
        {
            id: 'dietary_requirements',
            title: 'step4.dietary',
            value: '',
            compiled: false,
            required: false,
            type: 'textbox'
        },
    ])

    const [logistic, setLogistic] = useState([
        {
            id: 'fromCountry', title:"fromCountry", compiled: true, value: '', required: true
        },
        {
            id: 'fromCity', title:"fromCity", compiled: false, required: true, value: ''
        },
        {
            id: 'transportFirstStep', label:'firstStepLabel', fields: [
                {value: "privateCar", label: 'privateCar'},
                {value: "taxi", label: 'taxi'},
                {value: "train", label: 'train'},
                {value: "bus", label: 'bus'},
            ], compiled: false, required: true, type: 'radiogroup', other:true, direction:'column', value: ''
        },
        {
            id:'arrivalDateTime', title:"arrivalDateTime", compiled: false, required: true, type: 'datetimepicker', value: ''
        },
        {
            id: "arrivalTransport", title:"", fields: [
                {value: "ferry", label: 'byFerry'},
                {value: "plane", label: 'byPlane'},
            ], compiled: false, required: true, type: 'radiogroup', value: ''
        },
        {
            id: 'arrivalFromAirport', title:"departureAirport", compiled: false, required: true, type: 'text', value: ''
        },
        {
            id: 'arrivalToAirport', title:"arrivalAirport", compiled: false, required: true, type: 'text', value: ''
        },
        {
            id: 'arrivalAirline', title:"Arrival_Airline", compiled: false, required: false, type: 'text', value: ''
        },
        {
            id: 'arrivalFlightNumber', title:"Arrival_Flight_Number", compiled: false, required: false, type: 'text', value: ''
        },
        {
            id:'departureDateTime', title:"departureDateTime", compiled: false, required: true, type: 'datetimepicker', value: ''
        },
        {
            id: "departureTransport", title:"", fields: [
                {value: "ferry", label: 'byFerry'},
                {value: "plane", label: 'byPlane'},
            ], compiled: false, required: true, type: 'radiogroup', value: ''
        },
        {
            id: 'departureFromAirport', title:"departureAirport", compiled: false, required: true, type: 'text', value: ''
        },
        {
            id: 'departureToAirport', title:"arrivalAirport", compiled: false, required: true, type: 'text', value: ''
        },
        {
            id: 'departureAirline', title:"Departure_Airline", compiled: false, required: false, type: 'text', value: ''
        },
        {
            id: 'departureFlightNumber', title:"Departure_Flight_Number", compiled: false, required: false, type: 'text', value: ''
        },
        {
            id: 'toHotel', title:"toHotel", fields: [
                {value: "rentalCar", label: 'rentalCar'},
                {value: "taxi", label: 'taxi'},
                {value: "bus", label: 'bus'},
            ], compiled: false, required: true, type: 'radiogroup', value: ''
        },

    ])

    const handleRoommatesDetails = (details, value, id) => {
        setRoommates([roommates.filter(d => d.id === 'type')[0], ...details])
        const _changes = changes;
        if (!_changes['accommodation']) {
            _changes['accommodation'] = {};
        }
        if (!_changes['accommodation']['roommates']) {
            _changes['accommodation']['roommates'] = {};
        }
        _changes['accommodation']['roommates'][id] = value;
        console.log(_changes);
        setChanges(_changes);
    }

    // new Date(params.value).toLocaleDateString('it-IT')

    function handleChangeType(e) {
        console.log(e.target.value);
        let newRoommates = roommates.map(r => {
            if (r.id === 'type') {
                return {...r, value: e.target.value}
            }
            return r;
        })
        console.log(newRoommates);
        setRoommates(newRoommates);
        const _changes = changes;
        if (!_changes['accommodation']) {
            _changes['accommodation'] = {};
        }
        if (!_changes['accommodation']['roommates']) {
            _changes['accommodation']['roommates'] = {};
        }
        _changes['accommodation']['roommates']['type'] = e.target.value;
        console.log(_changes);
        setChanges(_changes);
    }

    function handleEdit(event, key, id) {
        if (key === 'general') {
            let newArr = general.map(obj => {
                if (obj.id === id) {
                    const _changes = changes;
                    if (!_changes['general']) {
                        _changes['general'] = {};
                    }
                    _changes['general'][id] = event.target.value;
                    setChanges(_changes);
                    return {...obj, value: event.target.value};
                }
                return obj;
            });
            setGeneral(newArr)
        } else if (key === 'document') {
            let newArr = document.map(obj => {
                if (obj.id === id) {
                    const _changes = changes;
                    if (!_changes['document']) {
                        _changes['document'] = {};
                    }
                    if (id === 'issued_on' || id === 'expiry_date') {
                        _changes['document'][id] = event;
                        setChanges(_changes);
                        return {...obj, value: event};
                    } else {
                        _changes['document'][id] = event.target.value;
                        setChanges(_changes);
                        return {...obj, value: event.target.value};
                    }
                }
                return obj;
            });
            setDocument(newArr)
        } else if (key === 'accommodation') {
            let newArr = accommodation.map(obj => {
                if (obj.id === id) {
                    const _changes = changes;
                    if (!_changes['accommodation']) {
                        _changes['accommodation'] = {};
                    }
                    if (id === 'in_date' || id === 'out_date' || id === 'issued_on' || id === 'expiry_date') {
                        _changes['accommodation'][id] = event;
                        setChanges(_changes);
                        return {...obj, value: event};
                    } else {
                        _changes['accommodation'][id] = event.target.value;
                        setChanges(_changes);
                        return {...obj, value: event.target.value};
                    }
                }
                return obj;
            });
            setAccommodation(newArr)
        } else if (key === 'logistic') {
            let newArr = logistic.map(obj => {
                if (obj.id === id) {
                    const _changes = changes;
                    if (!_changes['logistic']) {
                        _changes['logistic'] = {};
                    }
                    if (id === 'arrivalDateTime' || id === 'departureDateTime') {
                        _changes['logistic'][id] = event;
                        setChanges(_changes);
                        return {...obj, value: event};
                    } else {
                        _changes['logistic'][id] = event.target.value;
                        setChanges(_changes);
                        return {...obj, value: event.target.value};
                    }
                }
                return obj;
            });
            setLogistic(newArr)
        }
    }

    useEffect(() => {
        fetchData(id).then()
    }, [id])

    async function fetchData(id) {
        const response = await UserService.getUser(id)
        if (response.data && response.data.in_presence && response.data.in_presence === 'yes') {
            setPresenza(1);
            setOldPresenza(1);
        } else {
            setPresenza(0)
            setOldPresenza(0)
        }
        setUser(response.data)
        general.map(d => d.value = response.data[d.id])
        setGeneral([...general])

        if(response.data?.document) {
            document.map(d => d.value = response.data?.document[d.id])
            setDocument([...document])
        }

        contact.map(d => d.value = response.data[d.id])
        setContact([...contact])

        if(response.data?.accommodation) {
            accommodation.map(d => d.value = response.data?.accommodation[d.id])
            setAccommodation([...accommodation])

            if(response.data?.accommodation?.roommates) {
                roommates.map(d => d.value = response.data?.accommodation?.roommates[d.id])
                setRoommates([...roommates])
            }
        }

        if (response.data?.logistic) {
            logistic.map(d => d.value = response.data?.logistic[d.id])
            setLogistic([...logistic])
        }

        setLoading(false)
    }

    async function updateData(section) {
        console.log(section);
        const data = {
            type: section,
            id: id
        }
        if (section === 'general') {
            data.data = changes['general']
            await axios.post(API_URL + 'updateUser', data, {headers: authHeader()})
        } else if (section === 'document') {
            data.data = changes['document']
            console.log(data);
            await axios.post(API_URL + 'updateUser', data, {headers: authHeader()})
        } else if (section === 'accommodation') {
            if (changes['accommodation']) {
                data.data = changes['accommodation']
                console.log(data);
                await axios.post(API_URL + 'updateUser', data, {headers: authHeader()})
            }
        } else if (section === 'logistic') {
            if (changes['logistic']) {
                data.data = changes['logistic']
                console.log(data);
                await axios.post(API_URL + 'updateUser', data, {headers: authHeader()})
            }
        }
        setOpen(true);
    }

    const computeDisabled = (id) => {
        let ad = logistic.find(g => g.id === id)
        if (id === 'arrival') {
            let det = ad.details.find(d => d.id === 'transport')
            return det.value !== 'plane';
        } else if (id === 'departure') {
            let det = ad.details.find(d => d.id === 'transport')
            return det.value !== 'plane';
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <main>
            <Box style={{background: '#0d99d8'}}>
                <Container>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{width: '100%', maxWidth: '180px'}}>
                            <img src={logo} width={'100%'} style={{paddingRight: '5rem', margin: '1rem 0'}}/>
                        </div>
                    </div>
                </Container>
            </Box>
            <Container sx={{paddingTop: '2rem'}}>
                <Button LinkComponent={Link} to={"/"} variant="outlined" sx={{marginBottom: '2rem'}}
                        startIcon={<ArrowBack/>}>Torna all'elenco</Button>
                <Typography variant={"h4"} sx={{textAlign: 'left'}}>{user?.name} {user?.surname}</Typography>
                <Typography variant={"body1"} sx={{textAlign: 'left'}}>
                    I am: {user?.i_am}
                </Typography>
                <Typography variant={"body1"} sx={{textAlign: 'left'}}>
                    Partecipazione in Presenza: {user?.participatePerson === 'yes' ? <Check color={"success"} /> : <Close color={"error"} />}
                </Typography>
                <Typography variant={"body1"} sx={{textAlign: 'left'}}>
                    Dept./Country: {user?.deptCountry}
                </Typography>
                <Typography variant={"body1"} sx={{textAlign: 'left'}}>
                    Area: {user?.area}
                </Typography>
                <Typography variant={"body1"} sx={{textAlign: 'left'}}>
                    Job Title: {user?.jobTitle}
                </Typography>

                {user?.participatePerson === 'yes' && <Card sx={{padding: '1rem', margin: '1rem 0'}}>
                    <Typography variant={"h5"} sx={{textAlign: 'left'}}>General Info</Typography>
                    <Box component={"form"} sx={{
                        '& > :not(style)': {p: 1},
                    }}>
                        {general.map(g =>
                            <FormControl key={g.id} sx={{
                                width: '50%',
                                [theme.breakpoints.down('md')]: {
                                    width: '100%',
                                }, marginTop: '0.3rem'
                            }}>
                                {g.type === 'datePicker' ?
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            renderInput={(params) =>
                                                <TextField {...params} id="name-input"
                                                           variant={'standard'}/>}
                                            label={g.title}
                                            value={g.value}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(e) => handleEdit(e, 'general', g.id)}/>
                                    </LocalizationProvider>
                                    :
                                    <TextField label={g.title} variant={"standard"} disabled={g.compiled}
                                               required={g.required} id={g.id + "-input"} value={g.value}
                                               onChange={(e) => handleEdit(e, 'general', g.id)}/>
                                }
                            </FormControl>
                        )}
                    </Box>

                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        {<Button onClick={() => updateData('general')} variant="contained"
                                disabled={!changes['general']}
                                sx={{margin: '1rem 0'}} startIcon={<Save />}>Aggiorna</Button>}
                    </Box>
                </Card>}

                {user?.participatePerson === 'yes' && <Card sx={{padding: '1rem', margin: '1rem 0'}}>
                    <Typography variant={"h5"} sx={{textAlign: 'left'}}>Document</Typography>
                    <Box component={"form"} sx={{
                        '& > :not(style)': {p: 1},
                    }}>
                        {document.map(g =>
                            <FormControl key={g.id} sx={{
                                width: '50%',
                                [theme.breakpoints.down('md')]: {
                                    width: '100%',
                                }, marginTop: '0.3rem'
                            }}>
                                {g.type === 'datePicker' ?
                                    <Box width={'100%'}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <MobileDatePicker
                                                renderInput={(params) =>
                                                    <TextField {...params} id="name-input"
                                                               variant={'standard'}/>}
                                                label={g.title}
                                                value={g.value}
                                                inputFormat="dd/MM/yyyy"
                                                onChange={(e) => handleEdit(e, 'document', g.id)}/>
                                        </LocalizationProvider>
                                    </Box>
                                    :
                                    <Box width={'100%'}>
                                        <TextField variant={"standard"} label={g.title} fullWidth disabled={g.compiled}
                                                   required={g.required} id="name-input" value={g.value}
                                                   onChange={(e) => handleEdit(e, 'document', g.id)}/>
                                    </Box>
                                }
                            </FormControl>
                        )}
                    </Box>

                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        {<Button onClick={() => updateData('document')} variant="contained"
                                disabled={!changes['document']}
                                sx={{margin: '1rem 0'}} startIcon={<Save />}>Aggiorna</Button>}
                    </Box>
                </Card>}

                {user?.participatePerson === 'yes' && <Card sx={{padding: '1rem', margin: '1rem 0'}}>
                    <Typography variant={"h5"} sx={{textAlign: 'left'}}>Contact Info</Typography>
                    <Box component={"form"} sx={{
                        '& > :not(style)': {p: 1},
                    }}>
                        {contact.map(g =>
                            <FormControl key={g.id} sx={{
                                width: '50%',
                                [theme.breakpoints.down('md')]: {
                                    width: '100%',
                                }, marginTop: '0.3rem'
                            }}>
                                <TextField label={g.title} variant={"standard"} disabled={g.compiled}
                                           required={g.required} id="name-input" value={g.value}
                                           onChange={(e) => handleEdit(e, g.id)}/>
                            </FormControl>
                        )}
                    </Box>
                </Card>}

                {user?.participatePerson === 'yes' && <Card sx={{padding: '1rem', margin: '1rem 0'}}>
                    <Typography variant={"h5"} sx={{textAlign: 'left'}}>Accommodation Info</Typography>
                    <Box component={"form"} sx={{
                        '& > :not(style)': {p: 1},
                    }}>
                        {accommodation.map(g => {
                                switch (g.type) {
                                    case 'datepicker':
                                        return (
                                            <FormControl key={g.id} sx={{
                                                width: '30%',
                                                [theme.breakpoints.down('md')]: {
                                                    width: '100%',
                                                }, marginTop: '0.3rem'
                                            }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <MobileDatePicker
                                                        label={g.title}
                                                        inputFormat="dd/MM/yyyy"
                                                        value={g.value}
                                                        renderInput={(params) =>
                                                            <TextField {...params} disabled={g.compiled} required
                                                                       id="name-input" variant={'standard'}/>}
                                                        onChange={(e) => handleEdit(e, 'accommodation', g.id)}/>
                                                </LocalizationProvider>

                                            </FormControl>
                                        )
                                    case 'radiogroup':
                                        return (
                                            <FormControl key={g.id} sx={{
                                                width: '100%',
                                            }}>
                                                <Typography id="demo-simple-select-standard-label">{g.title}</Typography>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={g.value}
                                                    onChange={(e) => handleEdit(e, 'accommodation', g.id)}
                                                >
                                                    {
                                                        g.fields.map(v =>
                                                            <FormControlLabel value={v.value} control={<Radio/>}
                                                                              label={v.label}/>
                                                        )
                                                    }
                                                </RadioGroup>
                                            </FormControl>
                                        )
                                    case 'textbox':
                                        return (
                                            <FormControl key={g.id} sx={{
                                                width: '100%',
                                            }}>
                                                <Typography id="demo-simple-select-standard-label">{g.title}</Typography>
                                                <TextareaAutosize
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    value={g.value}
                                                    style={{width: '100%', fontFamily: 'inherit'}}
                                                    onChange={(e) => handleEdit(e, 'accommodation', g.id)}
                                                />
                                            </FormControl>
                                        )
                                    default:
                                        return (
                                            <FormControl key={g.id} sx={{
                                                width: '50%',
                                                [theme.breakpoints.down('md')]: {
                                                    width: '100%',
                                                }, marginTop: '0.3rem'
                                            }}>
                                                <TextField label={g.title} variant={"standard"} disabled={g.compiled}
                                                           required={g.required} id="name-input" value={g.value}
                                                           onChange={(e) => handleEdit(e, 'accommodation', g.id)}/>
                                            </FormControl>
                                        )
                                }
                            }
                        )}
                        {!loading && (accommodation.find(g => g.id === 'bed').value !== ('alone') && accommodation.find(g => g.id === 'bed').value !== ('')) &&
                            <RoommatesForm userData={user} roommates={roommates} handleChangeType={handleChangeType}
                                           handleRoommatesDetails={handleRoommatesDetails}/>
                        }
                    </Box>

                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        {<Button onClick={() => updateData('accommodation')} variant="contained" sx={{margin: '1rem 0'}}
                                disabled={!changes['accommodation']}
                                startIcon={<Save />}>Aggiorna</Button>}
                    </Box>
                </Card>}

                {user?.participatePerson === 'yes' && <Card sx={{padding: '1rem', margin: '1rem 0'}}>
                    <Typography variant={"h5"} sx={{textAlign: 'left'}}>Logistic Info</Typography>
                    <Box component={"form"} sx={{
                        '& > :not(style)': {p: 1},
                    }}>
                        {logistic.map(g =>
                            {
                                    switch (g.type) {
                                        case 'datetimepicker':
                                            return (
                                                <FormControl key={g.id} sx={{
                                                    width: '100%',
                                                }}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <MobileDateTimePicker
                                                            label={g.title}
                                                            value={g.value}
                                                            inputFormat="dd/MM/yyyy H:m a"
                                                            onChange={(e) => handleEdit(e, 'logistic', g.id)}
                                                            renderInput={(params) =>
                                                                <TextField {...params} id="name-input"
                                                                           variant={'standard'}/>}
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        case 'radiogroup':
                                            return (
                                                <FormControl key={g.id} sx={{
                                                    width: '100%'
                                                }}>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="controlled-radio-buttons-group"
                                                        value={g.value}
                                                        onChange={(e) => handleEdit(e, 'logistic', g.id)}
                                                    >
                                                        {
                                                            g.fields.map(v =>
                                                                <FormControlLabel value={v.value} control={<Radio/>}
                                                                                  label={v.label}/>
                                                            )
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                            )
                                        default:
                                            return (
                                                <FormControl key={g.id} sx={{
                                                    width: '50%',
                                                    [theme.breakpoints.down('md')]: {
                                                        width: '100%',
                                                    }, marginTop: '0.3rem'
                                                }}>
                                                    <TextField label={g.title} variant={"standard"}
                                                               disabled={computeDisabled(g.id)}
                                                               required={g.required} id="name-input" value={g.value}
                                                               onChange={(e) => handleEdit(e, 'logistic', g.id)}/>
                                                </FormControl>
                                            )
                                    }
                                }
                        )}
                    </Box>

                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        {<Button onClick={() => updateData('logistic')} variant="contained"
                                disabled={!changes['logistic']}
                                sx={{margin: '1rem 0'}} startIcon={<Save />}>Aggiorna</Button>}
                    </Box>
                </Card>}
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert elevation={6} variant={"filled"} onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Aggiornamento effettuato con successo
                </Alert>
            </Snackbar>
        </main>
    )
}

export default User
